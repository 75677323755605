import { ChangeEvent, useEffect, useState } from "react";
import * as Styled from "./styled";
import CircularChart from "../../components/charts/CircularChart";
import ProductionEmployeeDto from "../../dto/production-employee-data.dto";
import ProductionTable from "../../components/ProductionTable";
import ProductionCellDto from "../../dto/production-cell.dto";
import ProductionCells from "../../components/ProductionCells";
import Field from "../../components/Field";
import GoalChart from "../../components/charts/GoalChart";
import DashboardService from "../../services/dashboard.service";
import { parseError } from "../../config/error-handler";
import OptionDto from "../../dto/option.dto";
import Header from "../../components/template/Header";
import Row from "../../components/Row";
import Column from "../../components/Column";
import PackagingDto from "../../dto/packaging.dto";
import { useNavigate } from "react-router-dom";
import ProductionDto from "../../dto/production.dto";

// Constants
const dashboardService = new DashboardService();

const DashboardPage = () => {
  const [cellData, setCellData] = useState<ProductionCellDto>({
    id: 0,
    title: "", 
    productionType: "",
    goal: 0, 
    production: 0, 
    nc: 0, 
    workedHours: 0, 
    notWorkedHours: "",
    workstations: [],
  });
  const [view, setView] = useState('');
  const [productionEmployeesData, setProductionEmployeesData] = useState<ProductionEmployeeDto[]>([]);
  const [productions, setProductions] = useState<ProductionCellDto[]>([]);
  const [packaging, setPackaging] = useState<PackagingDto>({
    id: 0,
    production: 0,
    goal: 0,
    nc: 0,
    worked: 0,
    unavailability: 0
  });
  const [filterOptions, setFilterOptions] = useState<OptionDto[]>([]);
  const [filter, setFilter] = useState("");
  const [viewOptions, setViewOptions] = useState<OptionDto[]>([]);
  const [tvInfos, setTvInfos] = useState<ProductionDto[]>([{
    name: "",
    amount: 0,
    meta: 0,
    metaMovel: 0,
  }])

  const datesFunctions = [getStartDay(), getStartWeek(), getStartMonth()]

  useEffect(() => {
  dashboardService
    .getViewOptions()
    .then(setViewOptions)
    .catch(parseError);

  dashboardService
    .getFilterOptions()
    .then(filters => {
      dashboardService
        .getProductions(filters[0].id)
        .then(data => setCellData(data))
        .catch(parseError);

        dashboardService
        .getTvData(filters[0].id, getStartDay())
        .then(setTvInfos)
        .catch(parseError)

        dashboardService
        .getUsersByCell(filters[0].id)
        .then(setProductionEmployeesData)
        .catch(parseError);
      
        dashboardService
        .getAllCells(filters[0].id)
        .then(cells => {
          let totalMeta = 0; 
          let totalProduction = 0;
          let totalNC = 0;
          let totalWorkedHours = 0;
  
          cells?.workstations?.forEach(workstation => {
            totalMeta += workstation.goal
            totalProduction += workstation.unitsProduced
            totalNC += workstation.unitsProducedNc
        });
          
          setPackaging(prevPackaging => ({
            ...prevPackaging,
            production: totalProduction,
            goal: totalMeta, // Defina um valor padrão se goal for undefined
            nc: totalNC,
            id: 0, // Inclua o id do cell
            worked: 0, // Defina um valor inicial para worked
            unavailability: 0 // Defina um valor inicial para unavailability
          }));
        })
        .catch(parseError);

      setFilterOptions(filters)
      setFilter("1")
      setView("1")
    })
    .catch(parseError); 
  }, [])

  function getStartDay(){
    const startDay : Date = new Date()
    startDay.setHours(0,1,0,0)
    return startDay
  } 

  function getStartWeek(){
    const startWeek : Date = new Date()
    const diaDaSemana = startWeek.getDay()    
    const diasParaVoltar = (1 - diaDaSemana) * -1
    startWeek.setDate(startWeek.getDate() - diasParaVoltar )
    startWeek.setHours(0,1,0,0)
    return startWeek
  }

  function getStartMonth(){
    const startMonth : Date = new Date()
    startMonth.setDate(1)
    startMonth.setHours(0,1,0,0)
    return startMonth
  }

  function handleFilterChange(e : ChangeEvent<HTMLInputElement | HTMLTextAreaElement>){
    const value = e.target.value

    setFilter(value)

    dashboardService 
      .getProductions(parseInt(value))
      .then(data => setCellData(data))
      .catch(parseError)

    dashboardService
      .getTvData(parseInt(value), datesFunctions[parseInt(view) - 1])
      .then(setTvInfos)
      .catch(parseError)

    dashboardService
      .getUsersByCell(parseInt(value))
      .then(setProductionEmployeesData)
      .catch(parseError);
    
    dashboardService
      .getAllCells(parseInt(value))
      .then(cells => {
        let totalMeta = 0; 
        let totalProduction = 0;
        let totalNC = 0;
        let totalWorkedHours = 0;

        cells?.workstations?.forEach(workstation => {
            totalMeta += workstation.goal
            totalProduction += workstation.unitsProduced
            totalNC += workstation.unitsProducedNc
        });

        setPackaging(prevPackaging => ({
          ...prevPackaging,
          production: totalProduction,
          goal: totalMeta, // Defina um valor padrão se goal for undefined
          nc: totalNC,
          id: 0, // Inclua o id do cell
          worked: 0, // Defina um valor inicial para worked
          unavailability: 0 // Defina um valor inicial para unavailability
        }));
      })
      .catch(parseError);

  }

  function handleViewChange(e : ChangeEvent<HTMLInputElement | HTMLTextAreaElement>){
    const value = e.target.value
    setView(value)
    
    const indexArray = parseInt(value) - 1

    dashboardService
      .getTvData(parseInt(filter), datesFunctions[indexArray])
      .then(setTvInfos)
      .catch(parseError)
  }

  //---------------------------
  //  Botões "mostrar"
  //---------------------------

  const navigate = useNavigate();

  const goCellsPage = () => {
    navigate("/cells")
  }

  const goUsersPage = () => {
    navigate("/users")
  }

  const meta = packaging?.goal == null || packaging?.goal === 0  ? 1 : packaging?.goal 

  return (
    <>
      <Header title='Dashboard'>
        <Row>
          <Styled.Filter>
            <Styled.InputDefault
              size="small"
              select
              value={filter}
              onChange={event => handleFilterChange(event)}
              SelectProps={{
                native: true,
              }}
            >
              {filterOptions.map((option: OptionDto, index) => (
                <option key={option.id ?? index} value={option.id}>
                  {option.label}
                </option>
              ))}
            </Styled.InputDefault>
          </Styled.Filter>
          
          <Styled.Filter>
            <Styled.InputDefault
              highlight={true}
              size="small"
              select
              value={view}
              onChange={event => handleViewChange(event)}
              SelectProps={{
                native: true,
              }}
            >
              {viewOptions.map((option: OptionDto, index) => (
                <option key={option.id ?? index} value={option.id} style={{color:"black", fontFamily:"var(--Lato-font)"}}>
                  {option.label}
                </option>
              ))}
            </Styled.InputDefault>
          </Styled.Filter>
        </Row>
      </Header>

      <Row>
        <Styled.LeftColumn>
          <Styled.Card>
            <h2>Geral / empacotamento</h2>
            <Row>
              <Styled.Fields>
                <Column>
                  <Field label="Unidades produzidas" value={packaging?.production?.toString()} />
                  <Field label="Meta" value={packaging?.goal?.toString()} />
                </Column>
              </Styled.Fields>
              <Styled.ChartContainer>
                {packaging &&
                  <CircularChart
                    series={[parseFloat(((packaging?.production ?? 0) / (meta ?? 1) * 100).toFixed(1))]}
                    size='big'
                  />
                }
              </Styled.ChartContainer>
            </Row>
            <Row>
              <Styled.Fields>
                <Row>
                  <Field label="Unidades N/C" value={packaging?.nc?.toString()} />
                  <Field label="Trabalhadas" value={cellData?.workedHours.toString()+"h"} /> 
                  {/*<Field label="Trabalhadas" value={packaging?.worked.toString()} />*/}
                  <Field label="Indisponibilidade" value={cellData?.notWorkedHours.toString()+"h"} />
                </Row>
              </Styled.Fields>
            </Row>
          </Styled.Card>

          <Styled.FullCard>
            <h2>Células de produção</h2>
            <ProductionCells data={cellData} />
            <Styled.LinkButton onClick={goCellsPage}>
              Mostrar detalhes das células
            </Styled.LinkButton>
          </Styled.FullCard>
        </Styled.LeftColumn>

        <Styled.RightColumn>
          <Styled.Card>
            <h2>Volume de produção por funcionário</h2>
            <Styled.TableContainer>
              <ProductionTable data={productionEmployeesData} />
            </Styled.TableContainer>
            <Row>
              <Styled.LinkButton onClick={goUsersPage}>
                Mostrar todos funcionários
              </Styled.LinkButton>
            </Row>
          </Styled.Card>
          <Styled.FullCard>
            <h2>Acompanhamento instantâneo da Meta</h2>
            <GoalChart infos={tvInfos}/>
          </Styled.FullCard>

        </Styled.RightColumn>
      </Row>
    </>
  );
};

export default DashboardPage;
