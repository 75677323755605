import Header from "../../components/template/Header";
import * as Styled from "./styles";
import { useState } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { CSVLink } from "react-csv";
import AccentButton from "../../components/buttons/AccentButton";
import gregorian_pt_br from "react-date-object/locales/gregorian_pt_br";
import { LogsService } from "../../services/logs.service";
import { toast } from "react-toastify";
import { ReportDto } from "../../dto/report.dto";

const logsService = new LogsService();

export function Reports(){
  const [dates, setDates] = useState<DateObject[]>([]);
  const [data, setData] = useState<ReportDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  function onDateChange(e : DateObject[]){
    setDates(e);
    if(e.length === 2){
      logsService
        .getLogsBetweenDates(e[0].toDate(), e[1].toDate())
        .then((logs) => {
          setData(logs)
          console.log(logs)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  return (
    <>
      <Header title="Relatórios" />

      <Styled.MainContent>
        <Styled.FiltersContainer>
          <label>
            <CalendarMonthIcon />
            Filtrar por data
          </label>
          <DatePicker
            id="datePicker"
            name="datePicker"
            range
            rangeHover
            highlightToday={false}
            dateSeparator=" - "
            onChange={(e) => onDateChange(e)}
            inputClass="custom-input"
            placeholder={new Date().toLocaleString()}
            locale={gregorian_pt_br}
            format="DD/MM/YYYY"
          />
        </Styled.FiltersContainer>

        <AccentButton style={{ padding: '10px 20px', height: '50px', margin: '0px' }}>
          <CSVLink
            data={data}
            filename="Relatorios.csv"
            separator=";"
            enclosingCharacter={``}
            style={{ textDecoration: 'none', color: 'white' }}
            asyncOnClick={true}
          >
            {isLoading ? 'Carregando...' : 'Baixar arquivos'}
          </CSVLink>
        </AccentButton>
      </Styled.MainContent>
    </>
  );
}
