import {useState, useEffect} from "react";
import { DateContainer } from "./styles";

function ViewDate  () {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 60000); 

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  
  const formattedDate = currentDate.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return(
    <DateContainer>
      <p>Visualizando dia {formattedDate}</p>
    </DateContainer>
  );
}

export default ViewDate;