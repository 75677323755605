import { ReportDto } from "../dto/report.dto";
import Service from "./service";

export class LogsService extends Service {

  constructor() {
    super();
  }
  public async getLogs(): Promise<ReportDto[]>{
    try {
      const response = await this.remoteRequest.get('/logs/all');
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getLogsBetweenDates(firstDate : Date, secondDate : Date) : Promise<ReportDto[]>{
    try {
      const response = await this.remoteRequest.get(`/logs/${firstDate}/${secondDate}`)
      return response.data;
    }catch (error) {
      console.error(error)
      throw error
    }
  }

}