import styled from "styled-components";
import { TextField } from '@mui/material';

export const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
`

export const FiltersContainer = styled.div`
  box-sizing: border-box;
  width: max-content;
  height: max-content;
  position: relative;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 10px 15px;

  label{
    display: flex;
    align-items: center;
    gap: 0.5px;
  }

  .custom-input{
    border: 0px solid #ccc;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const DownloadButton = styled.div`
  font-family: var(--Lato-font);
  background-color: #80764B;
  color: white;
  padding: 12px 0;
  gap: 8px;
  width: 240px;

`


