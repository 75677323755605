import { useState } from 'react';
import * as Styled from './styled';
import LightLogo from '../LightLogo';
import UserDto from '../../dto/user.dto';
import {LockOpen} from '@mui/icons-material/';
import { useNavigate } from 'react-router-dom';

interface navBarprops{
  onMenuClick : any,
  user : UserDto
}

const Navbar = ({onMenuClick, user} : navBarprops) => {
  const navigate = useNavigate();
  const [dropDownOpen, setDropDownOpen] = useState(false);

  function handleDropDownOpen(){
    setDropDownOpen(!dropDownOpen)
  }

  function handleExit(){
    localStorage.clear()
    navigate("/login", {replace: true})
  }

  return (
  <Styled.Container>
    <Styled.HamburgerMenu onClick={onMenuClick}>
      <Styled.MenuLine></Styled.MenuLine>
      <Styled.MenuLine></Styled.MenuLine>
      <Styled.MenuLine></Styled.MenuLine>
    </Styled.HamburgerMenu>
    <LightLogo />
    <Styled.RowDoNavBar onClick={handleDropDownOpen}>
      <Styled.UserProfile>
        <Styled.UserPicture>
          {!user.picture ? 
          <img 
            src={"https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"}
            alt='Foto usuário'
          /> : 
          <img
            src={"/fotos/" + user.picture}
            alt="Foto usuário"
          />}
        </Styled.UserPicture>
        <Styled.Column>
          <Styled.Username>{user.name}</Styled.Username>
          <Styled.Role>{user.role === 1 ? "Administrador" : "Empregado"}</Styled.Role>
        </Styled.Column>
      </Styled.UserProfile>

      <Styled.DropdownMenu dropDownOpen={dropDownOpen}>
        <Styled.Account>
          Conta
        </Styled.Account>

        <Styled.ExitButton onClick={handleExit}>
          <LockOpen/>Sair
        </Styled.ExitButton>
      </Styled.DropdownMenu>

    </Styled.RowDoNavBar>
  </Styled.Container>
  )
}

export default Navbar;
